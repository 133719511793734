<template>
  <template v-if="!loading && !initialLoading">
    <LandingSafety v-if="isSafetyCatalog" />
    <LandingVision v-else />
  </template>
  <template v-else>
    <ZnLoadingBar />
  </template>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useRouter, ref, onMounted } from '#imports'
import { useGuestStore } from '~/store/guest'

const router = useRouter()
const initialLoading = ref(true)

const { isSafetyCatalog, loading, isGuestMode, guestStoreUrl } =
  storeToRefs(useGuestStore())
if (isGuestMode.value) {
  router.replace(guestStoreUrl.value)
}
onMounted(() => {
  initialLoading.value = false
})
</script>
